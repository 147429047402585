import { useState } from 'react';

const useApiCall = (callFunc, countFunc, withoutLoading, defaultData = null) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(defaultData);
  const [count, setCount] = useState(null);

  const handleApiCall = (params, callback = () => {}, errorCallback = () => {}) => {
    handleLoadingChange(true);
    if (callFunc) {
      callFunc(params)
        .then(res => {
          handleLoadingChange();
          setData(res?.data || defaultData);
          callback(res?.data || defaultData);
        })
        .catch(e => {
          handleLoadingChange();
          if (errorCallback && e?.response?.data) {
            errorCallback(e?.response?.data);
          }
        });
    }
    if (countFunc) {
      countFunc(params)
        .then(res => {
          setCount(res?.data || 0);
        })
        .catch(() => {});
    }
  };

  const handleLoadingChange = (value = false) => {
    if (!withoutLoading) {
      setLoading(value);
    }
  };

  const setDefaultValues = () => {
    setData(defaultData);
    setCount(0);
  };

  return [data, loading, handleApiCall, count, setDefaultValues];
};

export default useApiCall;
