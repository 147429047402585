import MainPage from 'pages/main.page';
import HomePage from 'pages/home.page';
import Verification from 'components/organisms/verification';
import FollowingTrip from 'components/organisms/following-trip';
import Stepper from 'components/organisms/stepper';
import FollowTrip from 'components/organisms/follow-trip';
import CatchUpPage from 'pages/catchUp.page';
import JoinCatchUp from 'components/organisms/join-catch-up';
import VerificationCatchUp from 'components/organisms/verification-catch-up';
import JoinedCatchUp from 'components/organisms/joined-catch-up';
import ResetWrapper from '../components/molecules/reset';

export const routes = {
  home: {
    key: 'home',
    path: '*',
    component: <HomePage />,
  },
  mainPage: {
    key: 'mainPage',
    path: '/trips/:name',
    component: <MainPage />,
  },
  followTrip: {
    key: 'followTrip',
    path: '/trips-detail/:tripId',
    component: <FollowTrip />,
  },
  verification: {
    key: 'verification',
    path: '/verify/:tripId',
    component: <Verification />,
  },
  followingTrip: {
    key: 'followingTrip',
    path: '/following-trips/:tripId',
    component: <FollowingTrip />,
  },
  signUp: {
    key: 'signUp',
    path: '/sign-up',
    component: <Stepper />,
  },
  catchUpPage: {
    key: 'catchUpPage',
    path: '/catch-up/:name',
    component: <CatchUpPage />,
  },
  joinCatchUp: {
    key: 'joinCatchUp',
    path: '/catch-up-detail/:name',
    component: <JoinCatchUp />,
  },
  verificationCatchUp: {
    key: 'verificationCatchUp',
    path: '/verify-catch-up/:name',
    component: <VerificationCatchUp />,
  },
  joinedCatchUp: {
    key: 'joinedCatchUp',
    path: '/joined-catch-up/:name',
    component: <JoinedCatchUp />,
  },
  reset: {
    key: 'reset',
    path: '/reset',
    component: <ResetWrapper />,
  },
};
