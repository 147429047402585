import axios from 'axios';
import { getAccessToken } from '../configs/local-storage';
import { API_ROOT } from '../configs/env-vars';

// eslint-disable-next-line import/no-anonymous-default-export
export default (headers = {}) => {
  const service = axios.create({
    baseURL: API_ROOT,
    headers: {
      Authorization: getAccessToken() ? 'Bearer '.concat(getAccessToken()) : '',
      ...headers,
    },
  });
  service.interceptors.response.use(
    response => response,
    error => {
      // const errorResponse = error.response;
      // if (errorResponse) {
      //   notification.error({
      //     message: 'Error',
      //     description: 'Something went wrong',
      //     maxCount: 1,
      //     duration: 0,
      //   });
      // }
      throw error;
    }
  );
  return service;
};
