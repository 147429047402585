import React, { useState } from 'react';
import { Input, Space } from 'antd';
import styled from 'styled-components';

const OtpInputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
`;

const OtpInput = styled(Input)`
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  margin-right: 8px;
  border: none;
  color: #3b016d;
  font-weight: 700;
  border-bottom: 2px solid #d9d9d9;
  border-radius: 0;
  &:hover {
    border-bottom: 2px solid #3b016d;
  }
  &:focus {
    box-shadow: none;
    border-bottom: 2px solid #3b016d;
  }
  &:active {
    border-bottom: 2px solid #3b016d;
  }
`;

const OtpInputComponent = ({ length = 6, onChange }) => {
  const [otp, setOtp] = useState(Array(length).fill(''));

  const handleChange = (value, index) => {
    if (/^[0-9]$/.test(value) || value === '') {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      onChange(newOtp.join(''));

      if (value && index < length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  return (
    <OtpInputWrapper>
      <Space>
        {otp.map((digit, index) => (
          <OtpInput
            key={index}
            id={`otp-input-${index}`}
            maxLength={1}
            value={digit}
            onChange={e => handleChange(e.target.value, index)}
          />
        ))}
      </Space>
    </OtpInputWrapper>
  );
};

export default OtpInputComponent;
