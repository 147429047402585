import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import InvitedUpcomingTrips from 'components/molecules/invited-upcoming-trips';
import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import Header from '../components/molecules/header';
import AvatarSection from '../components/molecules/avatar-section';
import TripsCard from '../components/molecules/cards/trips-card';
import GetTheApp from '../components/molecules/get-the-app';
import FollowedTripsCard from '../components/molecules/cards/followed-trips-card';
import PageLoader from '../components/molecules/page-loader';
// eslint-disable-next-line import/no-named-as-default
import useUserTrip from '../utils/hooks/useUserTrip';
import useAuth from '../utils/hooks/useAuth';
import { followTripApi } from '../services/trips';
import useApiCall from '../utils/hooks/useApiCall';
import GetAppQr from '../components/molecules/get-app-qr';
import { colors } from '../styles/colors';
import About from '../components/molecules/about';

const MainWrapper = styled.div`
  @media (min-width: 576px) {
    padding: 0px;
  }

  @media (min-width: 768px) {
    padding: 0px 50px;
  }

  @media (min-width: 992px) {
    padding: 0px 50px;
  }

  @media (min-width: 1200px) {
    padding: 0px 50px;
  }

  @media (min-width: 1400px) {
    padding: 0px 50px;
  }
  .mobile_view {
    display: block;
  }
  .desktop_view {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    .mobile_view {
      display: none;
    }

    .desktop_view {
      display: flex;
      justify-content: space-between;
      background-color: ${colors.faded_grey};
    }
  }
  @media (min-width: 1024px) {
    .left-section {
      margin: 0 2%;
    }
    .right-section {
      margin-right: 10%;
    }

    .left-section {
      flex: 0 0 60%;
      max-width: 60%;
      margin-top: 30px;
    }

    .right-section {
      flex: 0 0 20%;
      max-width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 40px;
    }
  }
`;

const MainPage = () => {
  const navigate = useNavigate();
  const {
    stateLoading,
    user,
    trips: initialTrips,
    expiryDate,
    userSlug,
    token,
    followedTrips: initialFollowedTrips,
  } = useUserTrip();
  const [, followTripLoading, followTripCall] = useApiCall(followTripApi);
  const { guestUser } = useAuth();

  const [trips, setTrips] = useState(initialTrips);
  const [followedTrips, setFollowedTrips] = useState(initialFollowedTrips);

  useEffect(() => {
    setTrips(initialTrips);
    setFollowedTrips(initialFollowedTrips);
  }, [initialTrips, initialFollowedTrips]);

  const followSingleTrip = id => {
    const followTripObj = {
      guestEmail: guestUser?.email,
      tripId: id,
    };
    followTripCall(
      followTripObj,
      () => {
        notification.success({ message: 'Trip followed successfully' });
        const updatedFollowedTrips = [...followedTrips, trips.find(trip => trip.id === id)];
        setTrips(trips.filter(trip => trip.id !== id));
        setFollowedTrips(updatedFollowedTrips);
      },
      error => {
        notification.error({ message: error.message });
      }
    );
  };

  const allTrips = useMemo(() => {
    const newTrips = trips.map(trip => {
      return { ...trip, isFollowed: false };
    });
    const newFollowedTrips = followedTrips.map(trip => {
      return { ...trip, isFollowed: true };
    });
    return [...newTrips, ...newFollowedTrips];
  }, [trips, followedTrips]);

  if (stateLoading || followTripLoading) {
    return <PageLoader />;
  }

  return (
    <MainWrapper>
      <div className='main_wrapper'>
        <Header />
        <InvitedUpcomingTrips userName={user?.fullName} />
        <AvatarSection
          avatarUrl={user?.avatar?.url}
          name={user?.fullName}
          expiryDate={expiryDate}
        />
        <div className='mobile_view'>
          <TripsCard
            data={trips}
            onButtonClick={tripId => {
              if (guestUser) {
                followSingleTrip(tripId);
              } else {
                navigate(`${`/trips-detail`}/${tripId}?token=${token}&userSlug=${userSlug}`);
              }
            }}
          />
          <GetTheApp name={user?.fullName} />
          <FollowedTripsCard followedTrips={followedTrips} />
        </div>

        <div className='desktop_view'>
          <div className='left-section'>
            <TripsCard
              data={allTrips}
              onButtonClick={tripId => {
                if (guestUser) {
                  followSingleTrip(tripId);
                } else {
                  navigate(`${`/trips-detail`}/${tripId}?token=${token}&userSlug=${userSlug}`);
                }
              }}
            />
            {/* <FollowedTripsCard followedTrips={followedTrips} /> */}
          </div>
          <div className='right-section'>
            <GetAppQr name={user?.fullName} />
          </div>
        </div>
        <About />
      </div>
    </MainWrapper>
  );
};

export default MainPage;
