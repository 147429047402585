const UserKey = 'ubqt_user';

const storeUser = user => {
  try {
    localStorage.setItem(UserKey, JSON.stringify(user));
  } catch (error) {
    return null;
  }
};

const getUser = () => {
  try {
    const user = localStorage.getItem(UserKey);
    return JSON.parse(user);
  } catch (error) {
    return null;
  }
};

const removeUser = () => {
  try {
    localStorage.removeItem(UserKey);
  } catch (error) {
    return null;
  }
};

export { getUser, removeUser, storeUser };
