import { LinkedinFilled } from '@ant-design/icons';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { LINKEDIN_CLIENT_ID } from '../../configs/env-vars';

const LinkedinSign = ({ handleSocialSign }) => {
  // `${API_ROOT}auth/linkedin/callback`,
  const redirectUri = `${window.location.origin}/linkedin`;
  const { linkedInLogin } = useLinkedIn({
    clientId: LINKEDIN_CLIENT_ID,
    redirectUri,
    onSuccess: code => {
      handleSocialSign('linkedin', null, code, redirectUri);
    },

    scope: ['openid', 'profile', 'email'],
  });

  return (
    <LinkedinFilled
      onClick={linkedInLogin}
      style={{ color: '#0077b5', margin: '0 30px', fontSize: 28 }}
    />
  );
};

export default LinkedinSign;
