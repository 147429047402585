import styled from 'styled-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../atoms';
import Logo from '../../assets/images/logo.png';

import AppStoreImage from '../../assets/images/app-store.png';
import GooglePlayImage from '../../assets/images/google-play.png';

const HeaderWrapper = styled.div`
  .header_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  .logo {
    width: 80px;
  }

  .market_images_wrapper_res {
    display: none;
  }

  .app_store_wrapper {
    width: 200px;
    margin-right: 10px;
  }

  .app_store_wrapper_two {
    width: 200px;
  }

  .button_res {
    display: block;
  }

  @media screen and (min-width: 768px) {
    .market_images_wrapper_res {
      display: flex;
    }

    .button_res {
      display: none;
    }
    .logo {
      width: 120px;
    }
    .header_wrapper {
      padding: 30px 0px;
    }
  }
`;

const Header = () => {
  const { t } = useTranslation();

  return (
    <HeaderWrapper>
      <div className='header_wrapper'>
        <div>
          <img src={Logo} alt='logo' className='logo' />
        </div>
        <div>
          <Button className='button_res'>{t('app.downloadApp')}</Button>
        </div>

        <div className='market_images_wrapper_res'>
          <a
            href='https://apps.apple.com/ca/app/ubqt/id6447530154'
            target='_blank'
            rel='noreferrer'
          >
            <img src={AppStoreImage} alt='app store' className='app_store_wrapper' />
          </a>
          <a
            href='https://play.google.com/store/apps/details?id=com.joinubqt.ubqt&pli=1'
            target='_blank'
            rel='noreferrer'
          >
            <img src={GooglePlayImage} alt='google play' className='app_store_wrapper_two' />
          </a>
        </div>
      </div>
    </HeaderWrapper>
  );
};

export default Header;
