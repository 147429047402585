import styled from 'styled-components';
import { colors } from '../../styles/colors';
import Logo from '../../assets/images/logo-vertical.png';
import AppStoreImage from '../../assets/images/app-store.png';
import GooglePlayImage from '../../assets/images/google-play.png';
import { Paragraph } from '../atoms';

const ThankYouWrapper = styled.div`
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  background-color: ${colors.main_color};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .inner_wrapper {
    width: 100%;
    max-width: 800px;

    .logo {
      margin-bottom: 40px;
    }

    .market_images_wrapper {
      display: flex;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;

      img {
        padding: 10px;
      }
    }
  }
  @media screen and (max-width: 576px) {
    padding: 30px;
    .inner_wrapper {
      .title {
        font-size: 35px;
        line-height: 50px;
        margin-bottom: 40px;
      }
      .subtitle {
        font-size: 16px;
      }
    }
  }
`;

const ThankYou = ({
  title = ' Thank you for',
  infoText = 'signing up',
  smallText = 'Please download the mobile app and login',
  secondSmallText = 'the same credentials to access the community',
  hasImages = true,
}) => {
  return (
    <ThankYouWrapper>
      <div className='inner_wrapper'>
        <img src={Logo} alt='logo' className='logo' />
        <Paragraph fz={64} fw={900} mb={60} color={colors.yellow} lh='70px' className='title'>
          {title} <br />
          <br />
          {infoText}
        </Paragraph>
        <Paragraph
          fz={21}
          mb={40}
          color={colors.electric_green}
          fw={400}
          lh='25px'
          className='subtitle'
        >
          {smallText} <br /> {secondSmallText}
        </Paragraph>
        {hasImages && (
          <div className='market_images_wrapper'>
            <a
              href='https://apps.apple.com/ca/app/ubqt/id6447530154'
              target='_blank'
              rel='noreferrer'
            >
              <img src={AppStoreImage} alt='app store' />
            </a>
            <a
              href='https://play.google.com/store/apps/details?id=com.joinubqt.ubqt&pli=1'
              target='_blank'
              rel='noreferrer'
            >
              <img src={GooglePlayImage} alt='google play' />
            </a>
          </div>
        )}
      </div>
    </ThankYouWrapper>
  );
};

export default ThankYou;
