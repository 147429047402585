import styled from 'styled-components';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { notification } from 'antd';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../styles/colors';
import TripHeader from '../molecules/trip-header';
import { Button } from '../atoms';
import OtpInputComponent from '../molecules/otp-input-component';
import PageLoader from '../molecules/page-loader';
import useAuth from '../../utils/hooks/useAuth';
import useApiCall from '../../utils/hooks/useApiCall';
import { resendOtp, verifyOtp } from '../../services/settings';
import { followTripApi } from '../../services/trips';
import { Typography } from '../../styles';

const VerificationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${colors.main_color};

  .verify_wrapper {
    background-color: ${colors.white};
    border-radius: 20px;
    width: 95%;
  }
  .form_section {
    background-color: ${colors.white};
    padding: 40px;
    margin-bottom: 15px;
  }
  .text {
    color: ${colors.greyText};
    font-size: 16px;
    text-align: center;
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
    font-weight: 400;
    line-height: 18px;
  }
  .button_section {
    text-align: center;
    margin-top: 25px;
  }
  .button {
    border-radius: 25px;
    padding: 10px 30px 10px 30px;
  }
  .card_wrapper {
    background-color: ${colors.faded_grey};
    padding: 20px;
  }

  .verification {
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
    font-weight: 700;
    line-height: 18px;
  }
  .email_text {
    text-align: center;
    font-size: 16px;
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
    font-weight: 400;
    line-height: 18px;
    color: ${colors.black};
  }
  .link_section {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-top: 20px;
    color: ${colors.main_color};
  }
  .code_text {
    margin-right: 5px;
    font-size: 16px;
    color: ${colors.light_grey};
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
    font-weight: 400;
    line-height: 18px;
  }
  .link_text {
    color: ${colors.main_color};
    font-size: 16px;
    cursor: pointer;
    text-decoration: underline;
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
    font-weight: 400;
    line-height: 18px;
  }
  @media screen and (min-width: 768px) {
    .verify_wrapper {
      margin: 25px;
      width: auto;
    }
    .card_wrapper {
      padding: 20px 50px;
    }
    .form_section {
      padding: 20px 80px;
    }
  }
`;

const Verification = () => {
  const params = useParams();
  const { guestUser } = useAuth();
  const navigation = useNavigate();
  const [otp, setOtp] = useState(null);
  const [searchParams] = useSearchParams();
  const avatar = searchParams.get('avatar');
  const slug = searchParams.get('userSlug');
  const time = searchParams.get('token');
  const { t } = useTranslation();

  const handleOtpChange = value => {
    if (value.length <= 4) {
      setOtp(value);
    }
  };

  useEffect(() => {
    if (otp?.length === 4) {
      saveVerifyOtp();
    }
  }, [otp]);

  const [, verifyOtpLoading, verifyOtpCall] = useApiCall(verifyOtp);
  const [, followTripLoading, followTripCall] = useApiCall(followTripApi);
  const [, resendOtpLoading, resendOtpCall] = useApiCall(resendOtp);

  const verifyOtpCallWrapper = (obj, callback, errorCallback) => {
    verifyOtpCall(
      obj,
      () => callback(),
      error => errorCallback(error)
    );
  };

  const followTripCallWrapper = (followTripObj, callback, errorCallback) => {
    followTripCall(
      followTripObj,
      () => callback(),
      error => errorCallback(error)
    );
  };

  const saveVerifyOtp = () => {
    const followTripObj = {
      guestEmail: guestUser?.email,
      tripId: params.tripId,
    };
    const obj = {
      email: guestUser?.email,
      otp,
    };

    verifyOtpCallWrapper(
      obj,
      () => {
        notification.success({ message: 'OTP verified' });

        followTripCallWrapper(
          followTripObj,
          () => {
            notification.success({ message: 'Followed trip successfully' });
            navigation(
              `${`/following-trips`}/${
                params.tripId
              }?token=${time}&userSlug=${slug}&avatar=${avatar}`
            );
          },
          error => {
            notification.error({ message: error.message });
          }
        );
      },
      error => {
        notification.error({ message: error.message });
      }
    );
  };

  const resendOtpHandler = () => {
    resendOtpCall(
      {
        email: guestUser?.email,
      },
      () => {
        notification.success({ message: 'OTP sent successfully' });
      },
      error => {
        notification.error({ message: error.message });
      }
    );
  };

  const onBackAndClose = () => {
    navigation(`/trips-detail/${params.tripId}?token=${time}&userSlug=${slug}`);
  };

  if (verifyOtpLoading || followTripLoading || resendOtpLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <VerificationWrapper>
        <div className='verify_wrapper'>
          <TripHeader
            avatarUrl={avatar}
            showBackArrow={true}
            title={t('app.followTripHeading')}
            showAvatar={true}
            onClose={onBackAndClose}
            onBack={onBackAndClose}
          />

          <div className='form_section'>
            <div className='verification'>{t('app.codeSent')}</div>
            <div className='text'>{t('app.codeSentMessage')}</div>
            <div className='email_text'>{guestUser?.email}</div>
            <OtpInputComponent length={4} onChange={handleOtpChange} />

            <div className='button_section'>
              <Button className='button' onClick={() => saveVerifyOtp()}>
                {t('app.continue')}
              </Button>
            </div>
            <div className='link_section'>
              <div className='code_text'>{t('app.noCode')}</div>
              <div className='link_text' onClick={resendOtpHandler}>
                {t('app.resendCode')}
              </div>
            </div>
          </div>
        </div>
      </VerificationWrapper>
    </>
  );
};

export default Verification;
