import { useEffect, useState } from 'react';
import { defFormLayout } from '../../../constants/etc';
import {
  Col,
  FormItem,
  Input,
  Row,
  Form,
  InputPassword,
  Checkbox,
  Button,
  Paragraph,
} from '../../atoms';
import { colors } from '../../../styles/colors';

const SecondStepForm = ({ form, handleSecSubmit, email, settings }) => {
  const formValues = Form.useWatch([], form);
  const [submittable, setSubmittable] = useState(false);

  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(() => {
        setSubmittable(!!form.getFieldValue('privacy'));
      })
      .catch(() => setSubmittable(false));
  }, [form, formValues]);

  const handleSubmit = async () => {
    const values = await form.validateFields();
    handleSecSubmit({ email: values.email, password: values.password });
  };

  return (
    <Form intialValues={email} form={form} {...defFormLayout} width='100%'>
      <Row gutter={[20, 10]} width='100%' margin='0'>
        <Col span={24}>
          {email ? (
            <FormItem
              name='email'
              initialValue={email || ''}
              rules={[
                { required: true, message: 'Email is required' },
                { type: 'email', message: 'Email is invalid!' },
              ]}
            >
              <Input placeholder='Email address' />
            </FormItem>
          ) : null}
        </Col>
        <Col sm={12} xs={24}>
          <FormItem
            name='password'
            help='Password must contain at least 8 characters, one uppercase, one number and one special case character'
            rules={[
              { required: true, message: 'Password is required!' },
              {
                pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/,
                message:
                  'Password must contain at least 8 characters, one uppercase, one number and one special case character',
              },
              ({ getFieldValue, setFields }) => ({
                validator(_, value) {
                  if (!getFieldValue('confirmPassword')) {
                    return Promise.resolve();
                  }
                  if (getFieldValue('confirmPassword') !== value) {
                    setFields([
                      {
                        name: 'confirmPassword',
                        value: getFieldValue('confirmPassword'),
                        errors: ['Password and Confirm password must be identical!'],
                      },
                    ]);
                  } else {
                    setFields([
                      {
                        name: 'confirmPassword',
                        value: getFieldValue('confirmPassword'),
                        errors: null,
                      },
                    ]);
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputPassword autoComplete='new-password' placeholder='Password' />
          </FormItem>
        </Col>
        <Col sm={12} xs={24}>
          <FormItem
            name='confirmPassword'
            rules={[
              { required: true, message: 'Confirm password is required!' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Password and Confirm password must be same!'));
                },
              }),
            ]}
          >
            <InputPassword autoComplete='new-password' placeholder='Confirm password' />
          </FormItem>
        </Col>
        <div className='section_padding'>
          <FormItem name='privacy' valuePropName='checked'>
            <Checkbox
              style={{
                marginLeft: '10px',
              }}
            >
              <div className='text_part_two'>
                <div
                  style={{
                    fontSize: '14px',
                    color: colors.greyColor,
                  }}
                >
                  I confirm that I’m over 18 and I agree to <br />
                </div>
                <Paragraph color='#BABABA' fz={14} mb={3}>
                  <a
                    href={settings?.policyLink ?? '#'}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: colors.main_color,
                    }}
                  >
                    Privacy Policy
                  </a>
                  {' and '}
                  <a
                    href={settings?.termsLink ?? '#'}
                    target='_blank'
                    rel='noopener noreferrer'
                    style={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      color: colors.main_color,
                    }}
                  >
                    Terms & Conditions
                  </a>
                </Paragraph>
              </div>
            </Checkbox>
          </FormItem>
        </div>

        <Col span={24} padding='30px 0 0'>
          <FormItem shouldUpdate={true}>
            {() => (
              <Button
                width='220px'
                type='outlined'
                radius='50px'
                onClick={handleSubmit}
                disabled={!submittable}
              >
                Create your account
              </Button>
            )}
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};

export default SecondStepForm;
