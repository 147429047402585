import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './assets/global.scss';
import 'react-phone-input-2/lib/style.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n'; // Import the i18n configuration

const root = createRoot(document.getElementById('root'));

root.render(<App />);

reportWebVitals();
