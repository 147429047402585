import client from './main-client';

export const fetchCallbackApi = ({ type, ...params }) =>
  client().get(`/auth/${type}/callback`, { params });

export const fetchAllSettingsApi = params => client().get('/setting', { params });

export const fetchCommunityInfoApi = data => client().post('/decryptUrl', data);

export const updateUserApi = params => client().put(`/users/${params.id}`, params.values);

export const userRegisterApi = data => client().post('/auth/local/register', data);

export const userResetPasswordApi = data => client().post('auth/reset-password', data);

export const guestUserLogin = data => client().post('/guest-users', data);

export const verifyOtp = data => client().post(`/guest-user/verify-otp`, data);

export const resendOtp = ({ email }) => client().get(`/guest-user/generate-otp/${email}`);
