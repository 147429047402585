import styled from 'styled-components';
import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { colors } from '../../styles/colors';
import backArrow from '../../assets/images/back-arrow.png';

const TripHeaderWrapper = styled.div`
  .inner_wrappers {
    display: flex;
    padding: 15px 20px;
    background-color: ${colors.white};
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .avatar {
    width: 46px;
    border-radius: 40px;
    margin-right: 10px;
  }
  .avatar_section {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.white};
  }
  .icon {
    display: flex;
    justify-content: flex-end;
    width: 10%;
    background-color: ${colors.white};
    cursor: pointer;
  }
  .title {
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
    color: ${colors.main_color};
  }
  .back_arrow {
    width: 9px;
    height: 15px;
  }
  .icon_two {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: ${colors.white};
    cursor: pointer;
  }
`;

const TripHeader = ({ showBackArrow, title, showAvatar, onBack, onClose, avatarUrl }) => {
  return (
    <TripHeaderWrapper>
      <div className='inner_wrappers'>
        {showBackArrow && (
          <div className='icon_two' onClick={onBack}>
            <img src={backArrow} alt='back arrow' className='back_arrow' />
          </div>
        )}
        <div className='avatar_section'>
          {showAvatar && <img src={avatarUrl} alt='avatar' className='avatar' />}
          <div className='title'>{title}</div>
        </div>
        <div className='icon' on onClick={onClose}>
          <CloseOutlined style={{ color: colors.grey }} />
        </div>
      </div>
    </TripHeaderWrapper>
  );
};

export default TripHeader;
