import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { getUser, removeUser, storeUser } from '../storage';

const useAuth = () => {
  const { setGuestUser, guestUser } = useContext(AuthContext);
  const [isAuthInitialized, setIsAuthInitialized] = useState(false);

  const guestUserSave = value => {
    setGuestUser(value);
    storeUser(value);
  };

  const Logout = () => {
    setGuestUser(null);
    removeUser();
  };

  useEffect(() => {
    const authUser = getUser();

    if (authUser) {
      setGuestUser(authUser);
      setIsAuthInitialized(true);
    } else setIsAuthInitialized(true);
  }, []);

  return {
    guestUserSave,
    Logout,
    setGuestUser,
    guestUser,
    storeUser,
    isAuthInitialized,
  };
};

export default useAuth;
