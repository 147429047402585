import styled, { css } from 'styled-components';
import { Input } from 'antd';
import { colors } from '../../../styles/colors';

const InputPassword = styled(Input.Password)`
  border: none !important;
  border-bottom: 1px solid ${colors.light_grey}!important;
  height: 40px;
  box-shadow: none;
  border-radius: 0;
  padding: 4px 15px;

  & input::placeholder {
    color: #bababa;
  }

  &:focus,
  &.ant-input-focused {
    outline: 0;
    box-shadow: none;
  }

  &:hover {
    border-color: ${colors.electric_green}!important;
  }
  &:focus,
  &.ant-input-affix-wrapper-focused {
    border-color: ${colors.electric_green}!important;
    outline: 0;
    box-shadow: none !important;
  }

  &[disabled] {
    color: #00000096;
    background-color: #fff;
    border: 1px solid #eeeeee;
  }
  ${props =>
    (props.mb || props.mb === 0) &&
    css`
      margin-bottom: ${props.mb}px;
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `}
  ${props =>
    props.radius &&
    css`
      border-radius: ${props.radius};
    `}
`;

export default InputPassword;
