import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';
import { colors } from '../../styles/colors';
import { Paragraph } from '../atoms';

const PageLoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .anticon {
    font-size: 100px;
    color: ${colors.main_color};
    margin-bottom: 30px;
  }
  .ant-typography {
    color: ${colors.main_color};
    font-size: 18px;
  }
`;

const PageLoader = () => {
  return (
    <PageLoaderWrapper>
      <LoadingOutlined />
      <Paragraph>Loading</Paragraph>
    </PageLoaderWrapper>
  );
};

export default PageLoader;
