import FacebookLogin from 'react-facebook-login';
import { FACEBOOK_APP_ID } from '../../configs/env-vars';
import FBLogo from '../../assets/images/fb-logo.png';

const FacebookSign = ({ handleSocialSign }) => {
  const responseFacebook = response => {
    if (response?.accessToken) {
      handleSocialSign('facebook', response?.accessToken);
    }
  };

  return (
    <div className='fb_wrapper'>
      <FacebookLogin
        appId={FACEBOOK_APP_ID}
        // autoLoad={true}
        fields='name,email,picture'
        icon={<img src={FBLogo} alt='facebook' />}
        textButton=''
        render={renderProps => <span onClick={renderProps.onClick}></span>}
        callback={responseFacebook}
      />
    </div>
  );
};

export default FacebookSign;
