import ResetPassword from 'components/organisms/reset-password/index';
import { htmlEscape } from 'utils/helpers';

const ResetWrapper = () => {
  const params = new URLSearchParams(window.location.search);
  const resetCode = params.get('code')?.toLowerCase() || null;

  return <ResetPassword resetCode={htmlEscape(resetCode)} />;
};

export default ResetWrapper;
