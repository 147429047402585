import styled from 'styled-components';
import { message } from 'antd';
import LogoDark from '../../assets/images/logo-dark.png';
import { Button, Paragraph } from '../atoms';
import { colors } from '../../styles/colors';

const JoinWrapper = styled.div`
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  background-color: ${colors.yellow};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  .inner_wrapper {
    width: 100%;
    max-width: 700px;

    .logo {
      width: 125px;
      margin-bottom: 40px;
    }

    .buttons_wrapper {
      display: flex;
      align-content: center;
      justify-content: center;
      flex-wrap: wrap;

      div {
        padding: 10px;
      }
    }
  }
  @media screen and (max-width: 576px) {
    padding: 30px;
    .inner_wrapper {
      .title {
        font-size: 35px;
        line-height: 50px;
        margin-bottom: 40px;
      }
      .subtitle {
        font-size: 16px;
      }
    }
  }
`;

const JoinCommunity = ({ setIsJoin }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(window.location.href)
      .then(() => {
        messageApi.open({
          type: 'success',
          content: 'Successfully copied',
        });
      })
      .catch(() => {
        messageApi.open({
          type: 'error',
          content: 'Error copying text to clipboard:',
        });
      });
  };

  return (
    <JoinWrapper>
      {contextHolder}
      <div className='inner_wrapper'>
        <img src={LogoDark} alt='logo' className='logo' />
        <Paragraph fz={64} fw={900} mb={60} color={colors.main_color} lh='70px' className='title'>
          It seems that you are <br /> on a desktop
        </Paragraph>
        <Paragraph fz={21} mb={40} color='#0C615A' fw={400} lh='25px' className='subtitle'>
          Copy the link to your mobile browser <br /> to continue joining the community
        </Paragraph>
        <div className='buttons_wrapper'>
          <div>
            <Button type='primary' radius='50px' width='180px' onClick={copyToClipboard}>
              Copy Link
            </Button>
          </div>
          <div>
            <Button
              type='outlined'
              radius='50px'
              width='180px'
              color={colors.main_color}
              border={`1px solid ${colors.main_color}`}
              onClick={() => setIsJoin(false)}
            >
              Back to sign up
            </Button>
          </div>
        </div>
      </div>
    </JoinWrapper>
  );
};

export default JoinCommunity;
