import styled from 'styled-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../styles/colors';

const AboutWrapper = styled.div`
  .about_wrapper {
    display: flex;
    align-items: center;
    padding: 40px 20px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    justify-content: center;
    flex-direction: column;
  }
  .link_wrapper_about {
    color: ${colors.main_color};
    cursor: pointer;
  }
`;

const About = () => {
  const { t } = useTranslation();

  return (
    <AboutWrapper>
      <div className='about_wrapper'>
        <div>{t('app.about')}</div>
        <div>
          <a className='link_wrapper_about' href='https://www.joinubqt.com'>
            www.joinubqt.com
          </a>
        </div>
      </div>
    </AboutWrapper>
  );
};

export default About;
