import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { Divider } from 'antd';
import { defFormLayout } from '../../../constants/etc';
import {
  Button,
  Checkbox,
  Col,
  Form,
  FormItem,
  Input,
  Paragraph,
  PlaceAutocompleteInput,
  Row,
} from '../../atoms';
import GoogleSign from '../google-sign';
import LinkedinSign from '../linkedin-sign';
import FacebookSign from '../facebook-sign';
import { colors } from '../../../styles/colors';

const FirstStepForm = ({
  form,
  socialData,
  settings,
  handleSocialSign,
  handleFirstSubmit,
  firstName,
  lastName,
}) => {
  const formValues = Form.useWatch([], form);
  const [locationValue, setLocationValue] = useState('');
  const [phoneData, setPhoneData] = useState(null);
  const [submittable, setSubmittable] = useState(false);

  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(() => {
        if (socialData) {
          setSubmittable(
            !!(
              socialData?.user?.id &&
              locationValue &&
              form.getFieldValue('privacy') &&
              form.getFieldValue('isAdult')
            )
          );
        } else {
          setSubmittable(!!locationValue);
        }
      })
      .catch(() => setSubmittable(false));
  }, [form, formValues, locationValue]);

  const handleSubmit = async () => {
    const values = await form.validateFields();
    if (!locationValue) {
      form.setFields([{ name: 'base', errors: ['Location is required!'] }]);
    }
    handleFirstSubmit({ ...values, phoneData, locationValue });
  };

  return (
    <>
      {!socialData && (
        <>
          <Paragraph fz={16} mb={0} color={colors.grey} fw={400}>
            Sign up with your social media account
          </Paragraph>
          <Row
            gutter={20}
            align='middle'
            justify='center'
            padding='10px 0'
            className='social_wrapper'
          >
            <GoogleSign handleSocialSign={handleSocialSign} />
            <LinkedinSign handleSocialSign={handleSocialSign} />
            <FacebookSign handleSocialSign={handleSocialSign} />
          </Row>
          <Divider className='divider'>Or</Divider>
        </>
      )}
      <Form intialValues={(firstName, lastName)} form={form} {...defFormLayout} width='100%'>
        <Row gutter={[20, 10]} width='100%' margin='0'>
          <Col sm={12} xs={24}>
            {firstName ? (
              <FormItem
                name='firstName'
                initialValue={firstName || ''}
                rules={[{ required: true, message: 'First Name is required' }]}
              >
                <Input placeholder='First Name' />
              </FormItem>
            ) : null}
          </Col>
          <Col sm={12} xs={24}>
            {lastName ? (
              <FormItem
                name='lastName'
                initialValue={lastName || ''}
                rules={[{ required: true, message: 'Last Name is required' }]}
              >
                <Input placeholder='Last Name' />
              </FormItem>
            ) : null}
          </Col>
          <Col sm={12} xs={24}>
            <FormItem
              name='phone'
              rules={[
                {
                  required: true,
                  message: 'Mobile number is required!',
                },
              ]}
            >
              <PhoneInput
                country='ae'
                placeholder='Mobile Number'
                onChange={(phone, { dialCode, countryCode }) => {
                  setPhoneData({ phone, countryCode, dialCode });
                }}
                countryCodeEditable={false}
                enableSearch={true}
                disableSearchIcon={true}
                searchPlaceholder='Search...'
                searchClass='phone_search'
              />
            </FormItem>
          </Col>
          <Col sm={12} xs={24}>
            <FormItem name='base'>
              <PlaceAutocompleteInput
                clientId={settings?.googleAPIKey}
                locationValue={locationValue}
                setLocationValue={setLocationValue}
                placeholder='Base location (city)'
                form={form}
                name='base'
              />
            </FormItem>
          </Col>
          {socialData && (
            <>
              <Col span={24} justify='start'>
                <FormItem name='privacy' valuePropName='checked'>
                  <Checkbox>
                    <Paragraph fz={12} color='#BABABA' mb={3}>
                      I agree to{' '}
                      <a
                        href={settings?.policyLink ?? '#'}
                        target='_blank'
                        rel='noopener noreferrer'
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                          color: colors.main_color,
                        }}
                      >
                        Privacy Policy
                      </a>
                      {' and '}
                      <a
                        href={settings?.termsLink ?? '#'}
                        target='_blank'
                        rel='noopener noreferrer'
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                          color: colors.main_color,
                        }}
                      >
                        Terms & Conditions
                      </a>
                    </Paragraph>
                  </Checkbox>
                </FormItem>
              </Col>
              <Col span={24} justify='start'>
                <FormItem name='isAdult' valuePropName='checked'>
                  <Checkbox>
                    <Paragraph fz={12} color='#BABABA' mb={3}>
                      I confirm that I’m over 18 to continue
                    </Paragraph>
                  </Checkbox>
                </FormItem>
              </Col>
            </>
          )}
          <Col span={24} padding='30px 0 0'>
            <FormItem shouldUpdate={true}>
              {() => (
                <Button
                  width='220px'
                  type='outlined'
                  radius='50px'
                  onClick={handleSubmit}
                  disabled={!submittable}
                >
                  {socialData?.user?.id ? 'Create your account' : 'Next'}
                </Button>
              )}
            </FormItem>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FirstStepForm;
