export const colors = {
  main_color: '#3b016d',
  dark_green: '#01605A',
  light_green: '#C3FEBA',
  yellow: '#E6F95D',
  electric_green: '#18F4BA',
  light_grey: '#BABABA',
  faded_grey: '#F7F7F7',
  white: '#FFFFFF',
  lightGreen: '#32F8BA',
  secondary: '#3b016d',
  disabledGreen: '#C3FCBA',
  darkGreen: '#01605A',
  darkGreenSecondary: '#003A37',
  greenSecondary: '#0C615A',
  lightGrey: '#D6D6D6',
  lightGreySecondary: '#EAEAEA',
  lightSilver: '#D9D9D9',
  secondaryText: '#333',
  darkTextColor: '#575756',
  greyText: '#666',
  purpleGrey: '#BDBCDB',
  disabledColor: '#BABABA',
  grey: '#bbb',
  greyColor: '#939393',
  grayVeryLight: '#F7F7F7',
  veryLightGrey: '#f4f4f4',
  dividerColor: '#E6E6E6',
  lightGray: '#BBBBBB',
  arrowColor: '#0C615A',
  lightRed: '#EFC0C2',
  error: '#C92B31',
  lowGrayColor: '#EFF5F4',
  blueSkyColor: '#007AFF',
  secondGray: '#9D9D9D',
  thirdGray: '#EFEFF0',
  fourGray: '#E7E7E7',
  disableNotificationBG: '#EFEFF050',
  black: '#000',
};
