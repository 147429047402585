import styled from 'styled-components';
import dayjs from 'dayjs';
import { Typography } from 'styles';
import { colors } from '../../../styles/colors';

const CatchUpJoinCardWrapper = styled.div`
  .inside_wrapper {
    background-color: ${colors.white};
    padding: 20px;
    border-radius: 15px;
  }
  .uper_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    font-weight: 700;
    max-width: 300px;
  }
  .lower_wrapper {
    display: flex;
    justify-content: space-between;
  }

  .trips_title {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    color: ${colors.black};
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
  }
  .trips_date {
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
  }
`;

const CatchUpJoinCard = ({ name, address, startDate, startTime }) => {
  return (
    <CatchUpJoinCardWrapper>
      <div className='inside_wrapper'>
        <div className='uper_wrapper'>
          <div className='trips_title'>
            {name},&nbsp;{address}
          </div>
        </div>
        <div className='lower_wrapper'>
          <div className='trips_date'>
            {dayjs(startDate).format('DD MMM YYYY')},&nbsp;
            {dayjs(startTime, 'HH:mm:ss').format('HH:mm')}
          </div>
        </div>
      </div>
    </CatchUpJoinCardWrapper>
  );
};

export default CatchUpJoinCard;
