/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import Button from 'components/atoms/Button/index';
import { colors } from '../../../styles/colors';
import Divider from '../../../assets/images/div.png';
import { Typography } from '../../../styles';

const FollowedTripsCardWrapper = styled.div`
  background-color: ${colors.faded_grey};

  .inner_wrapper_res {
    padding: 10px 20px;
  }

  .inside_wrapper_res {
    background-color: ${colors.white};
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 15px;
  }

  .uper_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .lower_wrapper {
    display: flex;
    // align-items: center;
    justify-content: space-between;
  }

  .divider {
    width: 40px;
  }

  .trips_date {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
  }

  .trips_title {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    color: ${colors.black};
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
  }

  .button_section_disabled {
    height: 28px;
    background-color: ${colors.light_grey};
    color: ${colors.white};
  }

  @media screen and (min-width: 768px) {
    .inner_wrapper_res {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .inside_wrapper_res {
      width: 49%;
    }
  }
`;

const FollowedTripsCard = ({ followedTrips }) => {
  const { t } = useTranslation();

  return (
    <FollowedTripsCardWrapper>
      <div className='inner_wrapper_res'>
        {followedTrips.map(trip => (
          <div key={trip.id} className='inside_wrapper_res'>
            <div className='uper_wrapper'>
              <div className='trips_title'>
                {trip.city && trip.country
                  ? `${trip.city}, ${trip.country}`
                  : trip.city || trip.country || ''}
              </div>
              <div>
                <Button className='button_section_disabled' disabled>
                  {t('app.followed')}
                </Button>
              </div>
            </div>
            <div className='lower_wrapper'>
              <div className='trips_date'>{dayjs(trip.startDate).format('DD MMM YYYY')}</div>
              <div>
                <img src={Divider} alt='divider' className='divider' />
              </div>
              <div className='trips_date'>{dayjs(trip.endDate).format('DD MMM YYYY')}</div>
            </div>
          </div>
        ))}
      </div>
    </FollowedTripsCardWrapper>
  );
};
export default FollowedTripsCard;
