import styled, { css } from 'styled-components';
import { Form as form } from 'antd';
import { colors } from '../../../styles/colors';

const Form = styled(form)`
  .ant-form-item:focus-within,
  .ant-form-item:hover {
    & .flag-dropdown {
      border-color: ${colors.electric_green};
    }
    input {
      border-color: ${colors.electric_green}!important;
      box-shadow: none !important;
    }
  }
  .react-tel-input {
    display: flex;
    flex-direction: row-reverse;

    & .special-label {
      display: none;
    }
    & .country-list {
      z-index: 5 !important;
      max-height: 150px;
      text-align: start;

      .phone_search {
        padding: 8px 10px;
        input {
          height: 30px;
          margin: 0;
          border: none;
          border-bottom: 1px solid ${colors.light_grey};
          border-radius: 0;
        }
      }
    }

    & .selected-flag,
    & .flag-dropdown {
      z-index: unset !important;
      position: unset;
      background: transparent;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      border: none;
    }
    & .flag-dropdown {
      border-bottom: 1px solid ${colors.light_grey};
      margin-right: 15px;
    }
    & input {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid ${colors.light_grey};
      height: 40px;
      background: #fff;
      box-sizing: border-box;
      padding: 0 15px;
      font-weight: 400;
      font-size: 14px;
      color: #5f6982;
      width: 100%;

      &::placeholder {
        color: #bababa;
      }
    }
  }
  ${props =>
    props.label_height &&
    css`
      & .ant-form-item-label,
      & .ant-form-item-label label {
        height: ${props.label_height} !important;
      }
    `}
  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}
  ${props =>
    props.overflow &&
    css`
      overflow: ${props.overflow};
    `}
  ${props =>
    props.justify &&
    css`
      justify-content: ${props.justify};
    `}
`;

export default Form;
