import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { notification } from 'antd';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { colors } from '../../styles/colors';
import Card from '../molecules/cards/card';
import TripHeader from '../molecules/trip-header';
import { FormItem, Form, Input, Checkbox, Button } from '../atoms';
import OrDivider from '../molecules/or-divider';
import useApiCall from '../../utils/hooks/useApiCall';
import { guestUserLogin } from '../../services/settings';
import { fetchSingleTripApi } from '../../services/trips';
import PageLoader from '../molecules/page-loader';
import useAuth from '../../utils/hooks/useAuth';
import { Typography } from '../../styles';

const FollowTripWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${colors.main_color};

  .follow_wrapper {
    background-color: ${colors.white};
    border-radius: 20px;
    width: 95%;
  }
  .form_section {
    background-color: ${colors.white};
    padding: 20px;
    margin-bottom: 15px;
  }
  .text {
    color: ${colors.greyText};
    margin-bottom: 15px;
    font-size: 16px;
    text-align: center;
    font-family: ${Typography.FONT_FAMILY_ROBOTO}
    font-weight: 400;
    line-height: 18px;
  }
  .button_section {
    text-align: center;
    margin-top: 20px;
  }
  .button {
    border-radius: 25px;
    padding: 10px 30px 10px 30px;
  }
  .card_wrapper {
    background-color: ${colors.faded_grey};
    padding: 20px;
  }
  .button_connect {
    background-color: ${colors.white};
    border: 1px solid ${colors.main_color};
    color: ${colors.main_color};
    border-radius: 20px;
  }
  .checkbox_text {
    color: ${colors.greyText};
    font-size: 16px;
    line-height: 18px;
    font-weight: 400;
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
  }

  @media screen and (min-width: 768px) {
    .follow_wrapper {
      margin: 25px;
      width: auto;
    }
    .card_wrapper {
      padding: 20px 50px;
    }
    .form_section {
      padding: 20px 50px;
    }
  }
`;

const platformsData = {
  iOS: 'https://apps.apple.com/ca/app/ubqt/id6447530154',
  Android: 'https://play.google.com/store/apps/details?id=com.joinubqt.ubqt&pli=1',
  MacOS: 'https://apps.apple.com/ca/app/ubqt/id6447530154',
  Windows: 'https://play.google.com/store/apps/details?id=com.joinubqt.ubqt&pli=1',
};

const FollowTrip = () => {
  const navigate = useNavigate();
  const { guestUserSave, guestUser } = useAuth();
  const params = useParams();
  const [trip, tripLoading, tripCall] = useApiCall(fetchSingleTripApi);
  const [state, setState] = useState({
    name: '',
    email: '',
    isChecked: true,
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [, guestUserLoading, guestUserCall] = useApiCall(guestUserLogin);

  const [searchParams] = useSearchParams();
  const time = searchParams.get('token');
  const slug = searchParams.get('userSlug');

  const { endTime, startTime, city, country, startDate, endDate, userId } = trip?.trip || {};

  const { t } = useTranslation();

  const handleChange = allValues => {
    setState({
      ...state,
      ...allValues,
    });
  };

  const handleSubmit = () => {
    guestUserCall(
      {
        name: state.name,
        email: state.email,
      },
      data => {
        guestUserSave(data);
        notification.success({ message: 'Guest user created successfully' });
        navigate(
          `${`/verify`}/${params.tripId}?token=${time}&userSlug=${slug}&avatar=${
            trip?.trip?.userId?.avatar?.url
          }&guestEmail=${guestUser?.email}`
        );
      },
      error => {
        notification.error({ message: error.message });
      }
    );
  };

  useEffect(() => {
    setIsButtonDisabled(!(state.name && state.email && state.isChecked));
  }, [state]);

  useEffect(() => {
    const obj = {
      id: params.tripId,
      token: time,
      userSlug: slug,
    };
    tripCall(obj);
  }, []);

  const getPlatform = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
      return 'MacOS';
    }

    if (/Win32|Win64|Windows|WinCE/.test(userAgent)) {
      return 'Windows';
    }

    return 'Other';
  };

  const platform = getPlatform();

  const href = platformsData[platform] || '#';
  if (tripLoading || guestUserLoading) {
    return <PageLoader />;
  }

  return (
    <>
      <FollowTripWrapper>
        <div className='follow_wrapper'>
          <TripHeader
            avatarUrl={userId?.avatar?.url}
            title={t('app.followTripHeading')}
            showAvatar={true}
            onClose={() => navigate(`/trips/${slug}?token=${time}`)}
          />

          <div className='card_wrapper'>
            <Card
              endTime={endTime}
              startTime={startTime}
              country={country}
              city={city}
              startDate={startDate}
              endDate={endDate}
            />
          </div>

          <div className='form_section'>
            <div className='text'>
              {t('app.fillForm')}
              <br />
              {t('app.calendar')}
            </div>
            <Form
              initialValues={state}
              layout='vertical'
              onFinish={handleSubmit}
              onValuesChange={handleChange}
            >
              <FormItem
                name='name'
                rules={[{ required: true, message: t('app.fullNameRequired') }]}
              >
                <Input placeholder={t('app.fullName')} />
              </FormItem>
              <FormItem
                name='email'
                rules={[
                  { required: true, message: t('app.emailRequired') },
                  { type: 'email', message: t('app.emailInvalid') },
                ]}
              >
                <Input placeholder={t('app.email')} />
              </FormItem>
              <FormItem name='isChecked' valuePropName='checked'>
                <Checkbox>
                  <div className='checkbox_text'>{t('app.acceptContentByEmail')}</div>
                </Checkbox>
              </FormItem>
            </Form>
            <div className='button_section'>
              <Button
                className='button'
                htmlType='submit'
                onClick={() => {
                  handleSubmit();
                }}
                disabled={isButtonDisabled}
                style={{
                  backgroundColor: isButtonDisabled ? colors.disabledColor : colors.main_color,
                  color: isButtonDisabled ? colors.white : colors.white,
                }}
              >
                {t('app.followTrip')}
              </Button>
            </div>
            <OrDivider />
            <div className='button_section'>
              <a href={href}>
                <Button className='button_connect'>{t('app.connectApp')}</Button>
              </a>
            </div>
          </div>
        </div>
      </FollowTripWrapper>
    </>
  );
};

export default FollowTrip;
