import React, { useState, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { AuthContext } from './context/AuthContext';
import { routes } from './common/routes';
import PageLoader from './components/molecules/page-loader';

function App() {
  const [guestUser, setGuestUser] = useState(null);
  return (
    <AuthContext.Provider value={{ guestUser, setGuestUser }}>
      <BrowserRouter>
        <Suspense fallback={<PageLoader />}>
          <Routes>
            {Object.values(routes).map(route => (
              <Route key={route.key} path={route.path} element={route.component} />
            ))}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </AuthContext.Provider>
  );
}

export default App;
