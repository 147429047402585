/* eslint-disable import/no-extraneous-dependencies */
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { colors } from '../../styles/colors';
import { Typography } from '../../styles';

const InvitedUpcomingTripsWrapper = styled.div`
  .upcoming_wrapper {
    padding: 10px 20px;
    background-color: ${colors.faded_grey};
  }
  .icon {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
  }
  .title {
    font-size: 16px;
    color: ${colors.greyText};
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
    font-weight: 400;
    line-height: 20px;
  }
  @media screen and (min-width: 768px) {
    .title {
      margin-bottom: 10px;
    }
  }
`;

const InvitedUpcomingTrips = ({ userName, onClose }) => {
  const { t } = useTranslation();

  return (
    <InvitedUpcomingTripsWrapper>
      <div className='upcoming_wrapper'>
        <div className='icon'>
          <CloseOutlined onClick={onClose} style={{ color: colors.greyText, cursor: 'pointer' }} />
        </div>
        <div className='title'>{t('app.inviteMessage', { userName })}</div>
      </div>
    </InvitedUpcomingTripsWrapper>
  );
};

export default InvitedUpcomingTrips;
