import styled from 'styled-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { colors } from '../../styles/colors';
import { Typography } from '../../styles';

const AvatarWrapper = styled.div`
  .avatar_wrapper {
    display: flex;
    align-items: center;
    padding: 20px 20px;
  }
  .avatar {
    width: 60px;
    height: 60px;
    border-radius: 40px;
    margin-right: 10px;
  }
  .title {
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    color: ${colors.main_color};
  }
  .description {
    font-size: 14px;
    font-weight: 200;
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
    line-height: 22px;
    color: ${colors.greyColor};
  }
  @media screen and (min-width: 768px) {
    .avatar_wrapper {
      padding: 30px 20px;
    }
  }
`;

const AvatarSection = ({ avatarUrl, name, expiryDate }) => {
  const { t } = useTranslation();

  return (
    <AvatarWrapper>
      <div className='avatar_wrapper'>
        <div>
          <img src={avatarUrl} alt='avatar' className='avatar' />
        </div>
        <div>
          <div className='title'>{name}</div>
          <div className='description'>
            {t('app.linkAvailable')}&nbsp;{dayjs(expiryDate).format('DD MMM YYYY')}
          </div>
        </div>
      </div>
    </AvatarWrapper>
  );
};

export default AvatarSection;
